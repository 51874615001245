import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

export const Mohawk3 = (props) => {
	return (
		<div id='mohawk3' className='text-center'>
		<Container >
			<Row className='justify-content-md-center'>
				<Col md='auto' >
					
					<Image 
						src="img/mohawk/wetprotectlogo.png"
						alt="..."
						style={{ maxWidth: '20rem' }}
					/>
				</Col>
						</Row>	
						<Row>
				<Col>
				
				<Button variant="danger"><a href="https://www.mohawkflooring.com/flooring-technologies/wetprotect" target="_blank">Learn More</a></Button>
				</Col>
				</Row>			
		</Container>
		</div>
	)
}
