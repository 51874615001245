export const Footer = (props) => {
  return (
    <div id='footer' >
            <div className='container text-center'>
              <p>
                &copy; 2022 Marbella Flooring.
          </p>
            </div>
          </div>
  )
}
