import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'




export const Featured2 = (props) => {
	return (
		<div className='text-center'>
			<Container>
				<Row className="justify-content-md-center">
					<Col md='auto'>

						<Card style={{ width: '18rem' }}>
							<a variant="primary" href="https://www.kahrs.com/en-gb/products/wood-flooring/" target="_blank" rel="noopener noreferrer">
								<Card.Img variant="top" src="img/logos/logo-kahrs.jpg" />
							</a>
							<Card.Body>
								<Card.Title>K&auml;hrs Flooring</Card.Title>
							</Card.Body>
						</Card>

						<Card style={{ width: '18rem' }}>
							<a variant="primary" href="https://www.armstrongflooring.com" target="_blank" rel="noopener noreferrer">
								<Card.Img variant="top" src="img/logos/logo-armstrong.jpg" />
							</a>
							<Card.Body>
								<Card.Title>Armstrong Flooring</Card.Title>
							</Card.Body>
						</Card>


					</Col>

					<Col md='auto'>

						<Card style={{ width: '18rem' }}>
							<a variant="primary" href="https://engineeredfloors.com" target="_blank" rel="noopener noreferrer">
								<Card.Img variant="top" src="img/logos/logo-engineered.jpg" />
							</a>
							<Card.Body>
								<Card.Title>Engineered Floors</Card.Title>
							</Card.Body>
						</Card>



						<Card style={{ width: '18rem' }}>
							<a variant="primary" href="https://ciot.com" target="_blank" rel="noopener noreferrer">
								<Card.Img variant="top" src="img/logos/logo-ciot.png" />
							</a>
							<Card.Body>
								<Card.Title>Ciot</Card.Title>
							</Card.Body>
						</Card>
					</Col>

					<Col md='auto'>

						<Card style={{ width: '18rem' }}>
							<a variant="primary" href="https://www.mohawkflooring.com" target="_blank" rel="noopener noreferrer">
								<Card.Img variant="top" src="img/logos/logo-mohawk.jpg" />
							</a>
							<Card.Body>
								<Card.Title>Mohawk Flooring</Card.Title>
							</Card.Body>
						</Card>

						<Card style={{ width: '18rem' }}>
							<a variant="primary" href="https://shawfloors.com" target="_blank" rel="noopener noreferrer">
								<Card.Img variant="top" src="img/logos/logo-shaw.png" />
							</a>
							<Card.Body>
								<Card.Title>Shaw Floors</Card.Title>
							</Card.Body>
						</Card>
					</Col>
					
					<Col md='auto'>
					
					<Card style={{ width: '18rem' }}>
							<a variant="primary" href="https://coretecfloors.com/en-us" target="_blank" rel="noopener noreferrer">
								<Card.Img variant="top" src="img/logos/COREtec-FLOORS_logo.jpg
								" />
							</a>
							<Card.Body>
								<Card.Title>Coretec Floors</Card.Title>
							</Card.Body>
						</Card>
					</Col>	
					<Col md='auto'>	
						
					<Card style={{ width: '18rem' }}>
							<a variant="primary" href="https://www.bruce.com/en-us/products/rigid-core.html?size=24" target="_blank" rel="noopener noreferrer">
								<Card.Img variant="top" src="img/logos/logo-bruce.jpg
								" />
							</a>
							<Card.Body>
								<Card.Title>Coretec Floors</Card.Title>
							</Card.Body>
						</Card>
						
					</Col>
					
				</Row>
				<br />
				<br />
			</Container>
		</div>
	)
}
