import React from 'react';
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Modal from 'react-bootstrap/Modal'

class GetRequest extends React.Component {
	constructor(props) {
		super(props);

		this.handleShow = this.handleShow.bind(this);
		this.handleClose = this.handleClose.bind(this);


		this.state = {
			data: [],
			show: false,
			imageSrc: null
		};

	}

	handleClose() {
		this.setState({ show: false });
	}

	handleShow(e) {
		this.setState({ show: true, imageSrc:e });
		
	}
	


	componentDidMount() {
		// Simple GET request using fetch
		fetch('http://localhost:3001/gallery/')
			.then(response => response.json())
			.then((data) => this.setState({ data: data }));
	}

	render() {
		const { data } = this.state;

		return (

			<div>

				<Col>
					{data.map(item => (
						<Card key={item.id}>							
							<a id={item.img} onClick={(e) => {this.handleShow(item.img, e)}  }  >
								<Card.Img variant="top" src={item.img} />
							</a>
						</Card>
					))}

				</Col>



				<div>


					<Modal  show={this.state.show} onHide={this.handleClose} size="lg" centered >
						<Modal.Body><img className="img-fluid" src={this.state.imageSrc} /></Modal.Body>
						<Modal.Footer>
							<button onClick={this.handleClose}>Close</button>
						</Modal.Footer>
					</Modal>


				</div>



			</div>


		);


	}
}

export { GetRequest }; 