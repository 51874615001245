import React from 'react'
import { useState } from 'react'
//import emailjs from 'emailjs-com'
import { XMapbox } from 'elements-x/dist/mapbox'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'



XMapbox.accessToken = "pk.eyJ1Ijoic2hpa2kiLCJhIjoiY2o4b29sNGE2MDZlYjMycDFsajBsMWcxaCJ9.SMjSpBjeHJXUSu8tbg7XpQ";




const initialState = {
	name: '',
	email: '',
	message: '',
}
export const Contact = (props) => {
	const [{ name, email, message }, setState] = useState(initialState)

	const handleChange = (e) => {
		const { name, value } = e.target
		setState((prevState) => ({ ...prevState, [name]: value }))
	}
	//const clearState = () => setState({ ...initialState })

	const handleSubmit = (e) => {
		e.preventDefault()
		console.log(name, email, message)
		/*
		 emailjs
		   .sendForm(
			 'YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', e.target, 'YOUR_USER_ID'
		   )
		   .then(
			 (result) => {
			   console.log(result.text)
			   clearState()
			 },
			 (error) => {
			   console.log(error.text)
			 }
		   )
		   */
	}
	return (
		<div >
			<div id='contact' >
				<div className='container' >

					<h2 className="text-center">Contact Us</h2>

					<h3>Contact Info</h3>

					<div className="row">
						<div className='col-md-8'>
							<x-div>
								<x-mapbox>
									<x-marker id="marker" lnglat="30649 Schoolcraft Livonia, Michigan 48150" center>
										<img src="img/blogo/marbella-logo2.png" width="45" height="45" />
									</x-marker>
								</x-mapbox>
							</x-div>
						</div>

						<div className='col-md-4'>
							<div >
								<div className='section-title'>

									<h2>Michigan</h2>

									<div className='contact-item'>
										<p>
											<span>
												<i className='fa fa-map-marker'></i> Address
                </span>
											{props.data ? props.data.address : 'loading'}
											<br />
											{props.data ? props.data.address2 : 'loading'}
										</p>
									</div>
									<div className='contact-item'>
										<p>
											<span>
												<i className='fa fa-phone'></i> Phone
                </span>{' '}
											{props.data ? props.data.phone : 'loading'}
										</p>
									</div>
									<div className='contact-item'>
										<p>
											<span>
												<i className='fa fa-envelope-o'></i> Email
                </span>{' '}

											<a href="mailto:sales@marbellaflooring.com">

												{props.data ? props.data.email : 'loading'}

											</a>
										</p>
									</div>
								</div>




							</div>

						</div>


					</div>


					<h3>
						<center>
							Coming soon in Florida.
				</center>
					</h3>





					<div className="row">
						<div className='col-md-8'>
							<x-div>
								<x-mapbox>
									<x-marker id="marker" lnglat="-82.00691709411906,26.606613908880405" zoomlevel="3" center>
										
									</x-marker>
								</x-mapbox>
							</x-div>
						</div>

						<div className='col-md-4'>
							<div >
								<div className='section-title'>

									<h2>Soon</h2>

									<div className='contact-item'>
										<p>
											<span>
												<i className='fa fa-map-marker'></i> Address
                </span>
											{props.data2 ? props.data2.address : 'loading'}
											<br />
											{props.data2 ? props.data2.address2 : 'loading'}
										</p>
									</div>
									<div className='contact-item'>
										<p>
											<span>
												<i className='fa fa-phone'></i> Phone
                </span>{' '}
											{props.data2 ? props.data2.phone : 'loading'}
										</p>
									</div>
									<div className='contact-item'>
										<p>
											<span>
												<i className='fa fa-envelope-o'></i> Email
                </span>{' '}

											<a href="mailto:sales@marbellaflooring.com">

												{props.data2 ? props.data2.email : 'loading'}

											</a>
										</p>
									</div>
								</div>




							</div>

						</div>


					</div>




					
				</div>


			</div>

		</div>
	)
}
