import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'

export const Mohawk = (props) => {
	return (
		<div id='mohawk' className='text-center'>
		<Container >
			<Row className='justify-content-md-center'>
				<Col md='auto' >
					
					<Image 
						src="img/mohawk/mohawk-top.jpg"
						roundedCircle="true"
						alt="..."
						style={{ maxWidth: '18rem' }}
					/>
					<br />
					<br />
					<h3>Innovative Flooring, Designed for You</h3>
					<p >
						Marbella Flooring, Primary retailer of Mohawk Flooring, produce the most innovative, fashionable flooring solutions to give you the beauty you want and the performance you need in every space of your home. With Mohawk, you can have it all: carpet, wood, tile, vinyl and rugs.
          			</p>
					<br />
					<p>
						<Button bsStyle="primary" href="#gallery">Explore Gallery</Button>
					</p>
				</Col>
				</Row>			
		</Container>
		</div>
	)
}
