import Carousel from "react-bootstrap/Carousel";
import Container from 'react-bootstrap/Container';

export const Header = (props) => {
	return (
		<div
			id='header'
		>
			<Container fluid>

				<Carousel fade indicators={false} pause={false}>

					<Carousel.Item interval={5000}>
						<img
							className="d-block w-100"
							src='img/header/ver2/1.jpg'
							alt=''
						/>
						<Carousel.Caption>
							<img
								className="w-50"
								src='img/header/Lg PNG.png'
								alt=''
							/>
							<h2><span>&nbsp;&nbsp;</span></h2>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={5000}>
						<img
							className="w-100"
							src='img/header/ver2/2.jpg'
							alt=''
						/>
						<Carousel.Caption>
							<img
								className="w-50"
								src='img/header/Lg PNG.png'
								alt=''
							/>
							<h2><span>&nbsp;&nbsp;</span></h2>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={5000}>
						<img
							className="w-100"
							src='img/header/ver2/3+.jpg'
							alt=''
						/>
						<Carousel.Caption>
							<img
								className="w-50"
								src='img/header/Lg PNG.png'
								alt=''
							/>
							<h2><span>&nbsp;&nbsp;</span></h2>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={5000}>
						<img
							className="w-100"
							src='img/header/ver2/4.jpg'
							alt=''
						/>
						<Carousel.Caption>
							<img
								className="w-50"
								src='img/header/Lg PNG.png'
								alt=''
							/>
							<h2><span>&nbsp;&nbsp;</span></h2>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={5000}>
						<img
							className="w-100"
							src='img/header/ver2/5.jpg'
							alt=''
						/>
						<Carousel.Caption>
							<img
								className="w-50"
								src='img/header/Lg PNG.png'
								alt=''
							/>
							<h2><span>&nbsp;&nbsp;</span></h2>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={5000}>
						<img
							className="w-100"
							src='img/header/ver2/6.jpg'
							alt=''
						/>
						<Carousel.Caption>
							<img
								className="w-50"
								src='img/header/Lg PNG.png'
								alt=''
							/>
							<h2><span>&nbsp;&nbsp;</span></h2>
						</Carousel.Caption>
					</Carousel.Item>

					<Carousel.Item interval={5000}>
						<img
							className="w-100"
							src='img/header/ver2/7.jpg'
							alt=''
						/>
						<Carousel.Caption>
							<img
								className="w-50"
								src='img/header/Lg PNG.png'
								alt=''
							/>
							<h2><span>&nbsp;&nbsp;</span></h2>
						</Carousel.Caption>
					</Carousel.Item>

				</Carousel>


			</Container>
		</div>
	)
}
