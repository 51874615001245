import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

export const Mohawk4 = (props) => {
	return (
		<div id='mohawk4' className='text-center'>
			<Container >
				<Row className='justify-content-md-center g-4'>
					<Col md='auto'>
						<Card className="bg-dark text-white">
							<Card.Img src="img/mohawk/pet-friendly.jpg" alt="..." />
							<Card.ImgOverlay className="h-100 d-flex flex-column justify-content-end">
								<Card.Title><h1>Pet Friendly</h1></Card.Title>
								<Card.Text>
								<p>
									<Button variant="danger mr-3"><a href="https://www.mohawkflooring.com/wood/search?page=1&specifications.DerivedWoodType.keyword=RevWood&specifications.DerivedWoodType.keyword=RevWood%20Select&specifications.DerivedWoodType.keyword=RevWood%20Plus" target="_blank">Hard Surfaces</a></Button>
									<span />
									<Button variant="danger"><a href="https://www.mohawkflooring.com/carpet/search?page=1&specifications.DerivedPetFriendly.keyword=Pet%20Friendly" target="_blank">Soft Surfaces</a></Button>
								</p>
								</Card.Text>
								<br />
							</Card.ImgOverlay>
						</Card>
					</Col>

					<Col md='auto'>
						<Card className="bg-dark text-white">
							<Card.Img src="img/mohawk/page-all-pet.jpg" alt="..." />
							<Card.ImgOverlay className="h-100 d-flex flex-column justify-content-end">
								<Card.Title><h1>Paw Proof</h1></Card.Title>
								<Card.Text>
								<p>All pets. All accidents. All the time.</p>
								<Button variant="danger"><a href="https://www.mohawkflooring.com/carpet/brand/allpet" target="_blank">Learn More</a></Button>
								</Card.Text>
								<br />
							</Card.ImgOverlay>
						</Card>
					</Col>
				</Row>

			</Container>
		</div>
	)
}
