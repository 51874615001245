import React, { useState, useEffect } from 'react'
import { Switch, Route } from 'react-router-dom';


import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';


export const Multi = (props) => {
	
	


	return (

	<div id='multi' className='container-fluid'>
    
	<Container fluid>
	
	<Row>
	
	<Col sm={1}></Col>
	<Col
	 style={{ textAlign: "center" }}
	> 
	

	<h1>Multi-Family Flooring Services and Benefits</h1>
	<div  style={{ padding: '50px' }}/>
	<Container fluid>
	
	<Row>
	
		<Col sm={3}> </Col>
	<Col>
	<br />
	<br />
	<h4>The choice of flooring plays a crucial role in enhancing both aesthetics and functionality.</h4>
	<h4>Our Multi-family flooring services offer a range of options, from durable vinyl and laminate to stylish hardwood and tile. These materials are designed to withstand heavy foot traffic and resist wear and tear, making them ideal for shared living spaces.
	</h4>
		<br />
	<br />
	
	</Col>
	
	<Col sm={3}> </Col>
	</Row>
	</Container>

	
	
	</Col>
	<Col sm={1}></Col>
	
	
	</Row>
	
	
	<Row>
	<Col sm={1}></Col>
	
	<Col>
<div  style={{ padding: '50px' }}/>
	<CardGroup>
       <Card 
		border="info"
		style={{ width: '18rem' }}
		className="mb-2"
		>
      <Card.Img variant="top" src="img/multi/mult2.jpg" />
      <Card.Body>
        <Card.Text>
          <p>Investing in high-quality flooring can lead to increased tenant satisfaction and retention, as well as improved property value.
			</p>
        </Card.Text>
      </Card.Body>
    </Card>
             <Card
		border="info" 
		style={{ width: '18rem' }}
		className="mb-2"
		>
      <Card.Img variant="top" src="img/multi/mult3.jpg" />
      <Card.Body>
        <Card.Text>
          <p>Expert installation to ensure a seamless and professional finish, further enhancing the overall appeal of their units.</p>
        </Card.Text>
      </Card.Body>
    </Card>
               <Card
		border="info" 
		style={{ width: '18rem', background:"white" }}
		className="mb-2"
		>
      <Card.Img variant="top" src="img/multi/mult1.jpg" />
      <Card.Body>
        <Card.Text>
          <p>Choosing the right flooring not only enhances the visual appeal but also supports long-term maintenance and cost efficiency in multi-family properties.</p>
        </Card.Text>
      </Card.Body>
    </Card>
    </CardGroup>
	
		<br />
	<br />
	
	
	</Col>
	
	<Col sm={1}></Col>
	
	</Row>
	
	
	
	</Container>

		
		
		
		
		
		
	
		
	
	
		</div>



		






	)
}
