import React, { useState, useEffect } from 'react';

class PostRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedFile: null,
			postId: null
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	

	handleChange(event) {
		this.setState({ selectedFile: event.target.files[0] });
		console.log(event.target.files[0].name);
	}

	handleSubmit(event) {
		
		const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: 4, img: this.state.selectedFile.name })
        };
		fetch('http://localhost:3001/gallery', requestOptions)
			.then(response => response.json())
            .then(data => this.setState({ postId: data.id }));
		
		event.preventDefault();
	}

	render() {
		const { postId } = this.state;
		return (
			<div>
				<h1> POST </h1>
				<form onSubmit={this.handleSubmit}>
					<label>
						Image:
          <input type="file" onChange={this.handleChange} />
					</label>
					<input type="submit" value="Submit" />
				</form>
				
				
				Returned Id: {postId}
			</div>
			
                

		);
	}
}

export { PostRequest }; 