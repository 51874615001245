import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';

export const Navigation = (props) => {
	return (

		<Navbar bg='light' expand='lg' fixed='top'>
			<Container>

				<Navbar.Brand href='#home' className='text-uppercase'>

					<img
						alt=''
						src='img/blogo/marbella-logo2.png'
						width='30'
						height='30'
						className='d-inline-block align-top'
					/>{' '}
				Marbella Flooring
				</Navbar.Brand>

				<Navbar.Toggle aria-controls='basic-navbar-nav' />

				<Navbar.Collapse id='basic-navbar-nav' className='justify-content-end'>
					<Nav className='me-auto'>
						<Nav.Link href='#about'>About</Nav.Link>
					</Nav>
					<Nav className='me-auto'>
						<Nav.Link href='#features'>Products</Nav.Link>
					</Nav>
					<Nav className='me-auto'>
						<Nav.Link href='#services'>Services</Nav.Link>
					</Nav>
					<Nav className='me-auto'>
						<Nav.Link href='#gallery'>Gallery</Nav.Link>
					</Nav>
					<Nav className='me-auto'>
						<Nav.Link href='#contact'>Contact</Nav.Link>
					</Nav>
					<Nav className='me-auto'>
						<Nav.Link href='#appointment'>Appointment</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>




	)
}
