import Container from 'react-bootstrap/Container'

export const Member = (props) => {
  return (
    <div id='member' >
			
            <div className='container text-center'>
				<h3>Associations & Memberships</h3>
				<br />
              <p>
               <img src="img/member/livonia_chamber_logo.png" />
          </p>
            </div>
     </div>
  )
}
