export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-4 col-md-4'>
            {' '}
			<br/>
			<h2 className='d-none d-lg-block'>Marbella Flooring</h2>
            <div className='d-none d-lg-block'><img src='img/blogo/marbella-logo2.png' className='img-fluid' alt='' />{' '}
			</div>
          </div>
          <div className='col-xs-8 col-md-8'>
            <div className='about-text'>
              <br />
			<br />
			<br />
			<p >
				<h4> 30649 Schoolcraft Rd, Livonia MI 48150 </h4>
				<h4> Marbellaflooring.com </h4>
				<h4> corporate@marbellaflooring.com </h4>
			
				<hr className = 'pull-left'style={{ width: "69%"}} />
				<br />
				<br />
							
				<h4> <span style={{ color:"lightseagreen" }} > Milad Dagher, CEO </span> (248) 800-0888 </h4>
				<h4> <span style={{ color:"lightseagreen" }} > Edgar Pantoja, Sales Manager </span> (248) 985-9999 </h4>
			</p>
			<br />
			</div>	
			</div>	
			</div>	
				
				
			<div className='justify-content-md-center'>
			
			<div className="about-text">	
				
				
			<h4 className="text-center" style={{ color:"orangered" }}> Our Mission And Vision </h4>
			
			<p>To be the most trusted provider of choice for multi-family, commercial and apartment Property Management by implementing innovative flooring solutions through quality products, excellent service and a highly trained, professional workforce.</p>
             
              <h4 style={{ color:"orangered" }}>Why Marbella?</h4>
              <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>

              </div>

			<br />
			
			<h4 style={{ color:"orangered" }}>What We Do: </h4>
			
				<p><h4 style={{ color:"lightseagreen" }}>Carpeting</h4>
				A wide selection of carpeting, for any setting, Rental multi-family units, High traffic common areas.</p>
				
			
				<p><h4 style={{ color:"lightseagreen" }}>Laminated Vinyl Plank</h4>
				A new line created to replicate the look of real hardwood in a durable, affordable luxury vinyl material in a variety of styles. Which is Very durable, Moisture - resistant, Has a more natural appearance, is Very easy to maintain and can be installed and repaired in-house.</p>
				
				
				<p><h4 style={{ color:"lightseagreen" }}>Vinyl Sheet</h4>
				Vinyl sheet is known for its versatile array of designs. You can find durable and stylish vinyl sheet flooring in a look to match any Setting.</p>
				
				
				<p><h4 style={{ color:"lightseagreen" }}>Engineered Hardwood Floors</h4>
				Hardwood floors are Naturally beautiful with a quality finish, solid foundation. They prove to be very durable and outlast all other flooring, it is also an environmentally-friendly option and cost-effective over time.</p>
				
			<br />
			
			<h4 style={{ color:"orangered" }}>Proven Record </h4>
			
			<p>
			With over 20 years as a flooring retailer, Marbella Flooring has been growing, with satisfied clients throughout the state of Michigan we have extended our reach to the states of Ohio, Indiana, and Illinois. We are proud to be a preferred flooring solutions provider for some of the biggest Property Management companies the Midwest.
			</p>
				

            </div>
          </div>
        </div>
      </div>
  )
}
