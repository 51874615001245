import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

export const Mohawk2 = (props) => {
	return (
		<div id='mohawk2' className='text-center'>
		<Container >
		<h3>Mowhak Premium Floors</h3>
		<hr />
		<br />
			<Row className='justify-content-md-center'>
				<Col>
				<a href="https://www.mohawkflooring.com/carpet/search?page=1" target="_blank">
				<Image 
						src="img/mohawk/carpet.jpg"
						roundedCircle="true"
						alt="..."
						style={{ maxWidth: '18rem' }}
					/>
				</a>
				<br />
				<br />	
				<h4>Carpet</h4>
				</Col>
				
				
				<Col>
				<a href="https://www.mohawkflooring.com/wood/search?page=1&specifications.DerivedWoodType.keyword=Engineered%20Wood&specifications.DerivedWoodType.keyword=RevWood%20Select&specifications.DerivedWoodType.keyword=RevWood&specifications.DerivedWoodType.keyword=RevWood%20Plus&specifications.DerivedWoodType.keyword=SolidWood&specifications.DerivedWoodType.keyword=TecWood&specifications.DerivedWoodType.keyword=TecWood%20Select" target="_blank">
				<Image 
						src="img/mohawk/laminate.jpg"
						roundedCircle="true"
						alt="..."
						style={{ maxWidth: '18rem' }}
					/>
				</a>
				<br />
				<br />	
				<h4>Laminated Wood</h4>
				</Col>
				
				
				<Col>
				<a href="https://www.mohawkflooring.com/wood/search?page=1&specifications.DerivedWoodType.keyword=Engineered%20Wood&specifications.DerivedWoodType.keyword=TecWood&specifications.DerivedWoodType.keyword=SolidWood&specifications.DerivedWoodType.keyword=TecWood%20Select" target="_blank">
				<Image 
						src="img/mohawk/hardwood.jpg"
						roundedCircle="true"
						alt="..."
						style={{ maxWidth: '18rem' }}
					/>
				</a>
				<br />
				<br />	
				<h4>Hardwood</h4>
				</Col>
				
			</Row>

			
		</Container>
		</div>
	)
}
