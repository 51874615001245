import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardColumns from 'react-bootstrap/CardColumns'
import Card from 'react-bootstrap/Card'

export const Services = (props) => {
	return (
		<div id='services' className='text-center'>
			<Container >
				<div style={{ paddingBottom: 30 }}>
					<h2>Our Services</h2>
					<h6>
						We offer a whole new concept from floor and texture planning, from installation and maintenance for the longest lasting beauty of the surface. All in an eco-friendly approach for our customers.
          			</h6>
				</div>

				<CardColumns>
				{props.data	? props.data.map((d, i) => (
					<Card bg='light' text="dark" className="p-0">
						<Card.Img variant="top" src={d.img} />
							
						
					</Card>

		))
						: 'loading'}

				</CardColumns>








			</Container>
		</div>
	)
}
