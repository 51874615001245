import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardDeck from 'react-bootstrap/CardDeck'
import Card from 'react-bootstrap/Card'

export const Features = (props) => {
	return (
		<Container id='features' >
			<Row className='justify-content-md-center'>
				<Col md='auto' >
					<h2 className="text-center">
						Products
					</h2>
					<p >
						Premier dealer on the following upscale premium quality flooring companies.
          			</p>
				</Col>
			</Row>

			<CardDeck className='justify-content-md-center'  style={{ textAlign: "center" }}>
				{props.data	? props.data.map((d, i) => (
						<Card border="light" key={i}>
							
							<a variant="primary" href={d.website} target="_blank" rel="noopener noreferrer">
							<Card.Img variant="top" src={d.img} />
							</a>
							<Card.Body>
								<Card.Title>{d.title}</Card.Title>
								<Card.Text>
									{d.text}
      							</Card.Text>
							</Card.Body>
						</Card>
					))
					: 'Loading...'}
			</CardDeck>
		</Container>
	)
}
