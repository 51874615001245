
import React, { useState } from 'react'
import CardColumns from 'react-bootstrap/CardColumns'
import CardGroup from 'react-bootstrap/CardGroup'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import Button from 'react-bootstrap/Button'
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { GetRequest } from './test2'


export const Gallery = (props) => {


	let imagePath = "img/gallery/20190827_144646_IMG_0461.JPG";




	const [isOpen, setIsOpen] = React.useState(false);

	const [state, setState] = useState({
		imageSrc: ""
	})



	const showModal = () => {
		setIsOpen(true);
	};

	const hideModal = () => {
		setIsOpen(false);
	};

	function handleChangeImage(i) {
		setState(prevState => {
			return {
				...prevState,
				imageSrc: i
			}
		})
		setIsOpen(true);
	}


	return (


		<div id='gallery' className='text-center' >

			<div className='container' >

				<div className='p-5'>

					<h2 className="text-center">Gallery</h2>
					<p>
						Installed Floors showcase.
          			</p>
				</div>
				
				
			
				<div>
					<section>

						<Tabs contentClass="tab-content-default" onSelect={(index, label) => console.log(`Selected Index: ${index}, Label: ${label}`)}>
							
							
							<Tab label="SolidTech">
							



							<br />
								<h2>SolidTech by Mohawk</h2>
								
								<h3>Waterproof, stain resistant and pet friendly floors with beautiful hardwood visuals, minus the maintenance and expense of real hardwood.</h3>
								
								<br />
								<br />
								
								<Image src="img/solidtech/mhk_solidtech_logo.jpg" fluid />
								<Image src="img/solidtech/mhk_solidtech_plus_features_graphic_1600x1335.png" fluid />
								<Image src="img/solidtech/allpetw.png" fluid />
								
								<br />
								<br />
								
								
								<h2>Rigid Core Flooring</h2>
								<p>One of the chief benefits of rigid core vinyl plank flooring is that it has a completely waterproof core. So, unlike hardwood flooring, you can install it in areas prone to moisture like your laundry room. Rigid Core is also a great flooring option for bathrooms and kitchens. It is available in a wide selection of colors with traditional hardwood, stone, or even distressed wood flooring looks.</p>
								
								
								<Image src="img/solidtech/mhk_solidtechE_logo.png" fluid />

								<br />								
								<p>Waterproof, pet friendly floors with added stain protection.</p>


								<br />
								<CardColumns>
									<p>
									{props.data16 ? props.data16.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}
</p>
								</CardColumns>
								<br />
								<Image src="img/solidtech/mhk_solidtechS_logo.png" fluid />
								
								<br />
								<p>Stain resistant and pet friendly floors with a lifetime surface and subfloor waterproof warranty.</p>
								<br />
								<CardColumns>

									{props.data17 ? props.data17.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
								
								<br />
								
								<Image src="img/solidtech/mhk_solidtechP_logo.png" fluid />
								
								<br />
								<p>Stain resistant and pet friendly floors with a lifetime surface and subfloor waterproof warranty, enhanced scratch protection plus antimicrobial technology.</p>
								<br />
								<CardColumns>

									{props.data18 ? props.data18.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
								
											
							</Tab>
							
							<Tab label="RevWood">
								<br />
								<h2> RevWood by Mohawk </h2>
								<br />
								
								
								<Image src="img/revwood/mhk_revwood_logo.webp" fluid />
								<br />
								<h3> Our most waterproof and scratch resistant laminated wood. </h3>
								
								<p> Featuring premium visuals, maximum scratch protection, and fade resistance in a variety of price points, RevWood offers long lasting beauty for any budget.
								</p>
								<p>
								
								
								 <ul>
									<li>&bull;4x more scratch resistant than other leading laminated wood products.</li>
									<li>&bull;10-Year Waterproof Warranty, safe for everyday spills and damp mopping.</li>
									<li>&bull;Mohawk's Lifetime All Pet&trade; Warranty covers all pets and all accidents.</li>
								</ul>	
								</p>							
								<br />
								
								<Image src="img/revwood/RevWood Select Logo.png" fluid />
								<br />
								
								<CardColumns>

									{props.data12 ? props.data12.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
								
								<br />
								<br />
								
								<Image src="img/revwood/RevWood_plus_logo.png" fluid />
								<br />
								
																<CardColumns>

									{props.data13 ? props.data13.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
								
								<br />
								<br />
								
								<Image src="img/revwood/RevWood_premier_logo.png" fluid />
								<br />
								
								<CardColumns>

									{props.data14 ? props.data14.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
								
								<br />
								<br />
								
							
							
							</Tab>
							
							<Tab label="Pergo Extreme">
							<br />
							<Image src="img/pergo/pergo_extreme_ultra_black3x.png" fluid />
							<br />
								
							<h3> 
							Ultrarealistic wood looks with waterproof performance and antimicrobial product protection. Seriously, does it get any better?
							</h3>
							
							<p> Live boldy on Pergo Extreme Ultra rigid vinyl floors featuring the latest
innovations in engineering and design. Naturally this floor features all the
durability you expect from Pergo making it the ultimate rigid vinyl floor. </p>
							
							<br />
							<br />
							<Image src="img/pergo/pergo_extreme_ultra_hero.png" fluid />
							<br />
							<br />
							<Image src="img/pergo/per_wetprotect_tagline_black_color_logo.png" fluid />
							<br />
							<p>
							A waterproof surface coating plus watertight joints trap liquids on the
surface to protect both the surface and the subfloor. Only floors
featuring WetProtect&reg; technology offer an exclusive WetProtect&reg;
Lifetime Surface and Subfloor Waterproof Warranty.
							</p>
							
							
							<Image src="img/pergo/pergo_realplank_logo.png" fluid />
							<br />
							<h3> Designed with Mother Nature's attention to detail. </h3>
							<p> RealPlank&trade; technology features more realistic colors, deeper textures and a natural edge finish for the most authentic wood look in a rigid vinyl floor.
							</p>
							<Image src="img/pergo/pergo_extreme_ultra_realplank_graphic.png" fluid />
							<br />
							<br />
							<Image src="img/pergo/cleanprotect_stacked_color_logo.png" fluid />
							<br />
							<br />
							<h3>
							Antimicrobial-protected flooring
							</h3>
							<p>
							CleanProtect&trade; built-in antimicrobial flooring protection inhibits the growth of bacteria that may harm the floor.
							</p>
							<Image src="img/pergo/pergo_extreme_cleanprotect_technology_detail.png" fluid />
							<br />
							<br />
							
							<CardColumns>

									{props.data15 ? props.data15.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
								
								<br />
								<br />
							
							</Tab>
							
							
							<Tab label="Mullican Flooring">
								<Container>
								<Row>
								<Col xs={1}></Col>
										<Col xs={10}>
										<br />
										<h2>Hardwood Flooring</h2>
										
										<p>
										Your flooring is the foundation of your home. So, we craft our products with artisan hands to bring the beauty and warmth of nature to your space.
										</p>
										<p>
										For every step, dance, crawl, or stumble taken on your floors-Mullican is there, for life. Our precision milling, advanced kiln-drying techniques, and the most demanding inspectors available result in a lifetime of value.
										</p>
										
										<CardColumns>

									{props.data6 ? props.data6.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
										
										
										
										</Col>
								<Col xs={1}></Col>
									
								
								
							</Row>
								</Container>
							</Tab>
							
							
							
							
							<Tab label="Waterproof Flooring">

								
								
								
								
								<Container>
									<Row>
										<Col xs={1}></Col>
										<Col xs={10}>
											<Image src="img/gallery/everlife/everlife-with-tagline-blue.png" fluid />
											<p>From luxury vinyl tile, porcelain and ceramic tiles to laminate and genuine wood, Everlife&reg; flooring is ideal for any room and complements any architectural style. In addition, a wide range of colors are available, allowing you to select the flooring that suits your design aesthetic and create your ideal space.
								</p>

											<Image src="img/gallery/everlife/waterproof.png" fluid />
											<br />
											<Image src="img/gallery/everlife/floorwaterproof.jpg" fluid />
											<br />
											<Image src="img/gallery/everlife/warranty.jpg" fluid />
											<br />
											<Image src="img/gallery/everlife/innovation.jpg" fluid />
											<br />
											<Image src="img/gallery/everlife/installation.jpg" fluid />



										</Col>
										<Col xs={1}></Col>
									</Row>
								</Container>
								
								<br />
								<h2>Shaw Floors - Floort&eacute;</h2>
								<p>Waterproof. Built to endure.</p>
								<Image src="img/floorte/floorte-umbrella-logo@3x.png" fluid />
								<br />
								<br />
								<CardColumns>

									{props.data10 ? props.data10.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
								
												
								
								

								<br />
								
								<h2>Andover&reg; Series</h2>
								<p>Andover&trade; Series from the Everlife&reg; Luxury Vinyl Tile (LVT) Collection offers modern convenience without sacrificing style. A 100% waterproof LVT, Andover&trade; is protected with 20 mil commercial-grade version of CrystaLux&trade;, an innovative wearlayer that guards against everyday scratches, spills, pet accidents, indentations, and sun damage. This low maintenance 7x48&rdquo; vinyl plank flooring is available in an inspirational variety of transitional to contemporary coastal wood styles and it&rsquo;s suitable for all grade-levels in both residential and commercial environments. Offering a custom designed painted beveled edge for a finished luxury look, Andover&trade; also comes with easy-install locking system and a pre-attached backing for quiet comfort under-foot.</p>

								<CardColumns>

									{props.data5 ? props.data5.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
								
								<br />

							<h2>Rigid Core - XL Prescott&reg; Series</h2>
							
							<CardColumns>

									{props.data7 ? props.data7.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
							
							
							<br />


							</Tab>


							<Tab label="General">
								<CardColumns>

									{props.data ? props.data.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
										</Card>

									))
										: 'loading'}

								</CardColumns>

							</Tab>


							<Tab label="Carpet">
							<br />
							<h2>Kane Carpet</h2>
							<p>Popular products</p>
							
							
							<CardColumns>
									{props.data8 ? props.data8.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>
							
							
							<br />
														
							<h2>Carpet Jobs</h2>

								<CardColumns>
									{props.data2 ? props.data2.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
										</Card>

									))
										: 'loading'}

								</CardColumns>

							</Tab>

							<Tab label="Tile Flooring">

								<CardColumns>
									{props.data3 ? props.data3.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
										</Card>

									))
										: 'loading'}

								</CardColumns>

							</Tab>

							<Tab label="Pattern">

								<CardColumns>
									{props.data4 ? props.data4.map((d, i) => (
										<Card key={i} >
											<a onClick={() => { handleChangeImage(d.img) }} >
												<Card.Img variant="top" src={d.img} />
											</a>
										</Card>

									))
										: 'loading'}

								</CardColumns>

							</Tab>
							
							
							<Tab label="Video">
							
		
							
							

								<CardColumns>
									{props.data9 ? props.data9.map((d, i) => (
										<Card key={i} style={{ width: '20rem' }} >	
											<video width="300" height="auto" controls>
												<source src={d.img} type="video/mp4"/>
												</video>
											<footer>
												{d.text}
											</footer>
										</Card>

									))
										: 'loading'}

								</CardColumns>

							</Tab>
							
						</Tabs>
						
						
							<br />
							<br />
							<br />
					</section>
				</div>




				<div>


					<Modal show={isOpen} onHide={hideModal} size="xl" centered >
						<Modal.Body><img className="img-fluid" src={state.imageSrc} /></Modal.Body>
						<Modal.Footer>
							<button onClick={hideModal}>Close</button>
						</Modal.Footer>
					</Modal>


				</div>


			</div>

		</div>





	)
}
