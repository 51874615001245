import Image from 'react-bootstrap/Image'

export const Flash = (props) => {
  return (
    <div id='flash'>
      <div className='container'>

		<div className = 'flash'>
		<Image src='img/Flash.png' fluid />
		</div>

       
      </div>
    </div>
  )
}
