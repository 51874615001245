import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'


export const Contact2 = () => {
	const form = useRef();
	
	const sendEmail = (e) => {
		e.preventDefault();

		emailjs.sendForm('service_g7haik4', 'template_r754z4v', form.current, 'user_vVM8YR1YaNCJzp3tZVs6B')
			.then((result) => {
				alert(`Thank you for your message. Your query has been forwarded.`);
				form.current.reset();
				console.log(result.text);
			}, (error) => {
				console.log(error.text);
			});
	};

	return (
		<div id="appointment">
		<div className='container' >
		<h3>Schedule an appointment</h3>
		
			<Form ref={form} onSubmit={sendEmail}>
				<Form.Group controlId="formFullName" >
					<Form.Label>Full name</Form.Label>
					<Form.Control placeholder="Full name" name="formFullName" required />
				</Form.Group>
				<Form.Group controlId="formEmail">
					<Form.Label>Email address</Form.Label>
					<Form.Control type="email" placeholder="Enter email" name="formEmail" required />
					<Form.Text className="text-muted">
						We'll never share your email with anyone else.
    							</Form.Text>
				</Form.Group>
				<Form.Group controlId="formMessage">
					<Form.Label>Description</Form.Label>
					<Form.Control as="textarea" rows={3} name="formMessage" required />
					<Form.Text className="text-muted">
						Tell us what are you interested in.
    				</Form.Text>
				</Form.Group>

				<Form.Group controlId="formAddress1">
					<Form.Label>Address</Form.Label>
					<Form.Control placeholder="1234 Main St" name="formAddress1" />
				</Form.Group>

				<Form.Group controlId="formAddress2">
					<Form.Label>Address 2</Form.Label>
					<Form.Control placeholder="Apartment, studio, or floor" name="formAddress2" />
				</Form.Group>

				<Form.Row>
					<Form.Group as={Col} controlId="formCity" >
						<Form.Label>City</Form.Label>
						<Form.Control name="formCity" required/>
					</Form.Group>

					<Form.Group as={Col} controlId="formState">
						<Form.Label>State</Form.Label>
						<Form.Control as="select" defaultValue="Choose..." name="formState" >
							<option>Choose...</option>
							<option>Michigan - MI</option>
							<option>Florida - FL</option>
						</Form.Control>
					</Form.Group>

					<Form.Group as={Col} controlId="formZip" >
						<Form.Label>Zip</Form.Label>
						<Form.Control name="formZip" required />
					</Form.Group>
				</Form.Row>

				<Button variant="primary" type="submit">
					Submit
  				</Button>
			</Form>
		</div>

</div>




	);
};